import React from 'react'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { SidebarWorkspaceNoticeTrial } from './WorkspaceSidebarNoticeTrial'
import {
  useCurrentWorkspaceSidebarNoticeId,
  WorkspaceNoticeId,
} from './workspaceNotices'
import { WorkspaceNoticeComponentProps } from './types'
import { WorkspaceSidebarNoticePersonalWorkspace } from './WorkspaceSidebarNoticePersonalWorkspace'
import { WorkspaceSidebarNoticeCanceledSubscription } from './WorkspaceSidebarNoticeCanceledSubscription'
import { WorkspaceSidebarNoticeEducationCancel } from './WorkspaceSidebarNoticeEducationCancel'

type WorkspaceSidebarNoticeProps = {
  workspace: WorkspaceMinimalFragment
}

/**
 * Component shown in the sidebar displaying a notice about the workspace status
 *  (trial, trial expired, subscription cancel, etc...) with potential call to action.
 */
export function WorkspaceSidebarNotice({
  workspace,
}: WorkspaceSidebarNoticeProps) {
  const mainWorkspaceNoticeId = useCurrentWorkspaceSidebarNoticeId(workspace)
  const WorkspaceNoticeComponent =
    workspaceNoticeIdToReactElementRecord[mainWorkspaceNoticeId]

  if (!mainWorkspaceNoticeId || !WorkspaceNoticeComponent) {
    // No notice to show
    return null
  }

  return <WorkspaceNoticeComponent workspace={workspace} />
}

/** Associate a react component to render with each notice id */
export const workspaceNoticeIdToReactElementRecord: Record<
  WorkspaceNoticeId,
  React.ComponentType<WorkspaceNoticeComponentProps> | null
> = {
  TRIAL: SidebarWorkspaceNoticeTrial,
  PERSONAL_WORKSPACE: WorkspaceSidebarNoticePersonalWorkspace,
  SUBSCRIPTION_CANCELED: WorkspaceSidebarNoticeCanceledSubscription,
  EDUCATION_CANCEL: WorkspaceSidebarNoticeEducationCancel,
}
