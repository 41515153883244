import React from 'react'

import {
  ElapsedTime,
  getElapsedTimeBetweenDate,
  createStringWithTimeUnit,
} from '@sketch/utils'
import {
  useGetBillingForWorkspaceNoticeQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { ReactComponent as LicenseIconMonochrome } from '@sketch/icons/license-diamond-monochrome-16'
import { ReactComponent as ExclamationMarkTriangleMonochromeIcon } from '@sketch/icons/exclamation-mark-triangle-monochrome-16'
import { WorkspaceSidebarNoticeContainer } from '../WorkspaceSidebarNoticeContainer'
import {
  NoticeContentTemplateFullLink,
  NoticeContentTemplateWithOptionalActionAndLearnMore,
} from '../NoticeContentTemplates'

import * as S from './WorkspaceSidebarNoticeTrial.styles'
import { WorkspaceNoticeComponentProps } from '../types'
import { Text, Link, Flex, LinkProps } from '@sketch/components'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { routes } from '@sketch/modules-common'
import { canManageSubscription } from '../workspaceStatusUtils'
import { useCanSubscribeViaStripe } from 'modules/workspace/utils'
import { ErrorHandler } from '@sketch/tracing'

type SidebarWorkspaceNoticeTrialProps = WorkspaceNoticeComponentProps

const IOS_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/iphone/iph3dfd91de/ios'

const MAC_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/app-store/fire5f3a0745/mac'

const getSubscriptionLink = (source: string | undefined) => {
  if (source === 'macappstore') return MAC_SUBSCRIPTION_DOCUMENTATION_LINK
  if (source === 'ios') return IOS_SUBSCRIPTION_DOCUMENTATION_LINK
  return ''
}

/**
 * Component to display when we want to show a notice related to the trial (active or expired).
 * The component has 3 substates:
 * - Active with more than 15 days left
 * - Active with less than 15 days left
 * - Trial has expired.
 */
export function SidebarWorkspaceNoticeTrial({
  workspace,
}: SidebarWorkspaceNoticeTrialProps) {
  const billingQuery = useGetBillingForWorkspaceNoticeQuery({
    variables: { identifier: workspace.identifier },
  })

  const billing = billingQuery.data?.workspace.customer?.billing
  const {
    canSubscribeStripe,
    source,
    loading: loadingStripeSub,
  } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )
  if (
    billingQuery.loading ||
    loadingStripeSub ||
    billingQuery.error ||
    !billing ||
    !billing.trialEnd
  ) {
    return null
  }

  if (billing.status === 'TRIALING') {
    const now = new Date()
    const subscribeLink = getSubscriptionLink(source)

    try {
      // Wrap in try in case getElapsedTimeBetweenDate returns an error
      // because endDate being set earlier than startDate just in case of data
      // inconsistency
      const elapsedTime = getElapsedTimeBetweenDate(
        now,
        new Date(billing.trialEnd)
      )
      const daysLeft = elapsedTime.toDays(
        // Use 'full' because of https://sketch.slack.com/archives/C04N2EPHUGN/p1682001080112139.
        { precision: 'full' }
      )

      if (daysLeft >= 15) {
        return (
          <ActiveTrialManyDaysLeft
            workspace={workspace}
            daysLeft={daysLeft}
            canSubscribeStripe={canSubscribeStripe}
            url={subscribeLink}
          />
        )
      } else {
        if (!canSubscribeStripe) {
          return (
            <ActiveTrialManyDaysLeftForMAS
              daysLeft={daysLeft}
              workspace={workspace}
              url={subscribeLink}
            />
          )
        }

        return (
          <ActiveTrialFewDaysLeft
            workspace={workspace}
            daysLeft={daysLeft}
            elapsedTime={elapsedTime}
            canSubscribeStripe={canSubscribeStripe}
          />
        )
      }
    } catch (err) {
      ErrorHandler.shouldNeverHappen('SidebarWorkspaceNoticeTrial render error')
      return null
    }
  }

  if (billing.status === 'CANCELED') {
    return (
      <EndedTrial
        workspace={workspace}
        canSubscribeStripe={canSubscribeStripe}
        url={getSubscriptionLink(source)}
      />
    )
  }

  return null
}

type ActiveTrialManyDaysLeftForMASProps = {
  daysLeft: number
  workspace: WorkspaceMinimalFragment
  url: string
}

type ActiveTrialManyDaysLeftProps = {
  daysLeft: number
  workspace: WorkspaceMinimalFragment
  canSubscribeStripe?: boolean
  url: string
}

function ActiveTrialManyDaysLeftForMAS({
  workspace,
  daysLeft,
  url,
}: ActiveTrialManyDaysLeftForMASProps) {
  return (
    <WorkspaceSidebarNoticeContainer>
      <Flex alignItems="top" fontSize="C">
        <S.NoticeIcon as={LicenseIconMonochrome} />
        <div>
          <Text
            mb={1}
            fontWeight={600}
          >{`${daysLeft} days left of your trial`}</Text>
          <Text mt={0} mb={2}>{`Subscribe using the Mac app`}</Text>
          {canManageSubscription(workspace.userRole) && (
            <Link href={url} variant="tertiary" isUnderlined external>
              Read More
            </Link>
          )}
        </div>
      </Flex>
    </WorkspaceSidebarNoticeContainer>
  )
}

function ActiveTrialManyDaysLeft({
  daysLeft,
  workspace,
  canSubscribeStripe,
  url,
}: ActiveTrialManyDaysLeftProps) {
  const link: LinkProps = canSubscribeStripe
    ? {
        to: routes.WORKSPACE_SUBSCRIBE.create({
          workspaceId: workspace.identifier,
        }),
      }
    : {
        href: url,
        external: true,
      }

  return (
    <WorkspaceSidebarNoticeContainer>
      <NoticeContentTemplateFullLink
        link={link}
        icon={LicenseIconMonochrome}
        disableLink={!canManageSubscription(workspace.userRole)}
      >
        <span>{`${daysLeft} days left `}</span>
        {`of your trial`}
      </NoticeContentTemplateFullLink>
    </WorkspaceSidebarNoticeContainer>
  )
}

type ActiveTrialFewDaysLeftProps = {
  daysLeft: number
  elapsedTime: ElapsedTime
  workspace: WorkspaceMinimalFragment
  canSubscribeStripe?: boolean
}

function ActiveTrialFewDaysLeft({
  daysLeft,
  elapsedTime,
  workspace,
  canSubscribeStripe,
}: ActiveTrialFewDaysLeftProps) {
  // Use 'full' because of https://sketch.slack.com/archives/C04N2EPHUGN/p1682001080112139.
  const hoursLeft = elapsedTime.toHours({ precision: 'full' })

  // Show the result in hours if less than 1 day
  const timeLeft =
    hoursLeft < 24
      ? createStringWithTimeUnit(hoursLeft, 'hour')
      : createStringWithTimeUnit(daysLeft, 'day')

  const showSubscribeLink =
    canSubscribeStripe && canManageSubscription(workspace.userRole)

  return (
    <WorkspaceSidebarNoticeContainer>
      <NoticeContentTemplateWithOptionalActionAndLearnMore
        action={
          showSubscribeLink
            ? {
                label: 'Subscribe',
                link: {
                  to: routes.WORKSPACE_SUBSCRIBE.create({
                    workspaceId: workspace.identifier,
                  }),
                },
              }
            : undefined
        }
        learnMoreLink={
          !canSubscribeStripe
            ? undefined
            : {
                href: `${SKETCH_WEBSITE}/pricing`,
                external: true,
              }
        }
        icon={LicenseIconMonochrome}
        title={`${timeLeft} left of your trial`}
      >
        <p>Subscribe to keep going!</p>
      </NoticeContentTemplateWithOptionalActionAndLearnMore>
    </WorkspaceSidebarNoticeContainer>
  )
}

type EndedTrialProps = {
  workspace: WorkspaceMinimalFragment
  canSubscribeStripe: boolean
  url: string
}

function EndedTrial({ workspace, canSubscribeStripe, url }: EndedTrialProps) {
  return (
    <WorkspaceSidebarNoticeContainer variant="black">
      <NoticeContentTemplateWithOptionalActionAndLearnMore
        action={
          canSubscribeStripe && canManageSubscription(workspace.userRole)
            ? {
                label: 'Subscribe',
                link: {
                  to: routes.WORKSPACE_SUBSCRIBE.create({
                    workspaceId: workspace.identifier,
                  }),
                },
              }
            : undefined
        }
        learnMoreLink={{
          href: canSubscribeStripe ? `${SKETCH_WEBSITE}/pricing` : url,
          external: true,
        }}
        icon={ExclamationMarkTriangleMonochromeIcon}
        title="Your trial has expired"
      >
        {canSubscribeStripe
          ? 'Subscribe to keep going!'
          : 'Subscribe using the Mac app'}
      </NoticeContentTemplateWithOptionalActionAndLearnMore>
    </WorkspaceSidebarNoticeContainer>
  )
}
