import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { FlatButton, Pill, Heading, TableComponents } from '@sketch/components'

export const BannerButton = styled(FlatButton)`
  padding: 0;
  text-decoration: underline;
`

export const Header = styled(Heading.H2).attrs({
  textStyle: 'header.primary.G',
})`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 24px;

  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${Pill} {
    margin-left: 8px;
  }
`

export const HeaderAfterCollections = styled(Header)`
  && {
    margin-top: 56px;
  }
`

export const DocumentsWrapper = styled.div<{
  listLayout: boolean
  $isRefreshedUi?: boolean
}>(
  ({ theme, listLayout, $isRefreshedUi }) => css`
    ${$isRefreshedUi &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `}

    background-image: linear-gradient(
      180deg,
      ${theme.colors.background.secondary.B} 0%,
      /* same colour, but opacity 0 */
        ${theme.colors.background.secondary.B.replace('1)', '0)')} 100%
    );
    /* negative margins needed so the background covers the full width */
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;

    margin-top: ${listLayout ? 12 : $isRefreshedUi ? 0 : 24}px;

    h2 {
      margin-top: 24px;
    }

    /* table styling, opposite colours */
    ${TableComponents.TableRow} {
      :nth-child(odd) td {
        background-color: ${({ theme }) => theme.colors.background.secondary.A};
      }

      :nth-child(even) td {
        background-color: ${({ theme }) => theme.colors.background.secondary.B};
      }
    }
    ${TableComponents.TableHeaderCell} {
      background-color: ${({ theme }) => theme.colors.background.secondary.B};

      ::before {
        background-color: ${({ theme }) => theme.colors.background.secondary.B};
      }
    }

    ${breakpoint('sm')`
    margin-top: ${listLayout ? 0 : $isRefreshedUi ? 0 : 40}px;
    padding-top: ${listLayout ? '50px' : undefined};

    ${
      !$isRefreshedUi &&
      css`
        margin-left: -40px;
        margin-right: -40px;
        padding-left: 40px;
        padding-right: 40px;
      `
    }

    h2 {
        margin-top: 40px;
    }


  `}
  `
)
