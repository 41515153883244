import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { PageBeginnerMarker, StyledHeader } from './Header.styles.js';
import '../Breakpoint/index.js';
import { useForTablet } from '../Breakpoint/Breakpoint.js';

const HEADER_SLIM_PORTAL_ID = 'header-slim-portal';
const useMobileScrolled = () => {
    const [isScrolled, setIsScrolled] = React.useState(false);
    const isTabletAndBigger = useForTablet();
    const isMobile = !isTabletAndBigger;
    React.useEffect(() => {
        const scrollingContainer = document.querySelector('[data-content-scrollable]');
        // Only run this effect on mobile
        if (isMobile && scrollingContainer) {
            const handleScroll = () => {
                setIsScrolled(scrollingContainer.scrollTop > 10);
            };
            scrollingContainer.addEventListener('scroll', handleScroll, {
                capture: true,
            });
            return () => {
                scrollingContainer.removeEventListener('scroll', handleScroll, {
                    capture: true,
                });
            };
        }
    }, [isMobile]);
    return isScrolled;
};
const useShouldShowStickyHeader = () => {
    // We only want to show the slim header when the portal exists
    const [shouldShowStickyHeader, setShouldShowStickyHeader] = React.useState(false);
    // We only want to show the slim header for tablet and desktop views
    const isTabletAndBigger = useForTablet();
    React.useEffect(() => {
        setShouldShowStickyHeader(!!document.getElementById(HEADER_SLIM_PORTAL_ID));
    }, []);
    return isTabletAndBigger && shouldShowStickyHeader;
};
const Header = ({ children }) => {
    const { ref, inView } = useInView({
        initialInView: true,
    });
    const { ref: markerRef, inView: beginnerMarker } = useInView({
        initialInView: true,
    });
    const shouldShowStickyHeader = useShouldShowStickyHeader();
    const headerSticky = !inView && shouldShowStickyHeader;
    const showBackground = !beginnerMarker && shouldShowStickyHeader;
    const mobileScrolled = useMobileScrolled();
    return (jsxs(Fragment, { children: [jsx(PageBeginnerMarker, { ref: markerRef }), jsx(StyledHeader, Object.assign({ ref: ref, "$sticky": headerSticky, "$background": showBackground || headerSticky, "$mobileScrolled": mobileScrolled }, { children: children }))] }));
};

export { Header };
