import styled, { css } from 'styled-components'

import {
  Box,
  Text,
  TruncateWithTooltip,
  Tooltip,
  Caption,
  Button,
  ButtonUnstyled,
  LinkUnstyled,
  Markdown as BaseMarkdown,
} from '@sketch/components'
import { linkStyles, breakpoint } from '@sketch/global-styles'

import { ReactComponent as CheckIcon } from '@sketch/icons/check-16'
import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-circle-11'
import { ReactComponent as ArrowDownCircle } from '@sketch/icons/arrow-down-circle-16'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-24'
import { ReactComponent as BellStrikeThroughIcon } from '@sketch/icons/bell-strikethrough-24'
import { ReactComponent as StarIcon } from '@sketch/icons/star-16'

export const MarkNotificationAsReadButton = styled(Button).attrs({
  size: '32',
})`
  padding: 4px 20px;
`

export const SubscriptionButton = styled(MarkNotificationAsReadButton).attrs({
  size: '32',
})`
  margin-left: 12px;
`

// Notification structure
export const NotificationContainer = styled(LinkUnstyled)<{
  $isRead?: boolean
}>(
  ({ theme, $isRead }) => css`
    display: flex;
    align-items: flex-start;

    &:hover {
      background: ${theme.colors.state.hover};

      ${breakpoint('base', 'sm')`
        background: none;
      `}
    }

    padding-left: ${!$isRead ? 24 : 0}px;

    white-space: pre-wrap;
    color: inherit;

    position: relative;
    transition: background ${theme.transitions.duration[1]}
      ${theme.transitions.timing.easeOut};

    /* stylelint-disable-next-line no-descending-specificity */
    &:hover,
    [data-testid='notifications-read-title'] ~ &:hover {
      ${breakpoint('base', 'sm')`
        border-radius: ${theme.radii.large};

        ${breakpoint('xs')`
          border-radius: ${theme.radii.xlarge};
        `}
      
      `}

      ${MarkAsReadButtonContainer} {
        opacity: 1;
      }
    }

    ${NotificationFlex} {
      padding: 16px;
      margin-left: ${!$isRead ? '16px' : '0px'};

      ${breakpoint('base', 'sm')`
        padding: 0px;
        margin-left: 0px;
      `}
    }
  `
)

export const NotificationContainerMobile = styled(NotificationContainer)<{
  $isRead?: boolean
}>(
  () => css`
    margin-left: 0;

    ${breakpoint('xs')`
        padding: 16px;
        margin-left: 0;
    `}

    /* stylelint-disable-next-line no-descending-specificity */
    ${MarkAsReadButtonContainer} {
      padding: 8px;
      align-self: end;
    }
  `
)

export const NotificationFlex = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;

  ${({ theme }) => css`
    border-radius: ${theme.radii.large};

    ${breakpoint('xs')`
       border-radius: ${theme.radii.xlarge};
     `}
  `}
`

export const NotificationFlexMobile = styled(NotificationFlex)`
  flex-direction: column;
`

export const NotificationItemContainer = styled.div`
  min-width: 0;
  display: flex;
  flex: 4 1 auto;
  flex-direction: column;
`

export const NotificationItemContainerMobile = styled(
  NotificationItemContainer
)(
  () => css`
    width: 100%;
    margin-left: 0;

    ${NotificationBody} {
      align-items: flex-start;

      /* stylelint-disable-next-line no-descending-specificity */
      ${Box} {
        margin-top: 2px; /* stylelint-disable-line scales/space */
      }
    }
  `
)

export const NotificationShareName = styled(TruncateWithTooltip)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    color: ${theme.colors.foreground.secondary.D};
    font-weight: ${theme.fontWeights.regular};
  `
)

export const NotificationDescription = styled(Text)(
  ({ theme }) => css`
    margin: 0;
    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.regular};
    line-height: 1.2;
    overflow-wrap: 'break-word';
  `
)

export const NotificationBody = styled(Box).attrs({
  mt: 1,
  mb: 0,
})(
  ({ theme }) => css`
    display: flex;
    flex: 1;

    align-items: center;

    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.E};
    color: ${theme.colors.foreground.secondary.B};
    overflow-wrap: break-word;
  `
)

export const NotificationsDate = styled(Caption).attrs({
  mt: 1,
  mb: 0,
  ml: 2,
})(
  ({ theme }) => css`
    display: block;
    color: ${theme.colors.foreground.secondary.C};
    font-size: ${theme.fontSizes.D};
    flex: 0 0 150px;
    text-align: center;
  `
)

export const NotificationsDateMobile = styled(NotificationsDate)`
  text-align: right;
`

export const NotificationsType = styled(Box).attrs({
  mt: 1,
  mb: 0,
  ml: 2,
})(
  ({ theme }) => css`
    display: block;
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    flex: 0 0 105px;
    text-align: center;
  `
)

// Other styles
export const UnreadIndicator = styled.div`
  position: relative;

  background: ${({ theme }) => theme.colors.sketch.A};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: auto 0;
  flex: 0 0 8px;
`

export const MobileHeader = styled.div<{ $isRead: boolean }>(
  ({ $isRead }) => css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    ${UnreadIndicator} {
      &:before {
        top: 12px;
        left: 0;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    ${NotificationsType} {
      margin-left: ${$isRead ? 0 : 16}px;
      display: inline;
    }
  `
)

export const FailedAsset = styled.div`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const MarkAsReadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0;
  margin-left: 8px;

  @media (hover: hover) {
    opacity: 0;
  }
`

const iconCSS = css`
  width: 22px;
  height: 22px;
  margin: -8px;
  flex: 0 0 auto;
`

export const MarkAsReadIcon = styled(CheckIcon)`
  ${iconCSS}
`

export const SubscribeIcon = styled(BellIcon)`
  ${iconCSS}
`

export const UnsubscribeIcon = styled(BellStrikeThroughIcon)`
  ${iconCSS}
`

export const StarredIcon = styled(StarIcon)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const FailedIcon = styled(ExclamationMarkIcon)`
  width: 11px;
  height: 11px;
  flex: 0 0 auto;
`

export const AssetReadyIcon = styled(ArrowDownCircle)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 2px; /* stylelint-disable-line scales/space */
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const TooltipAvatar = styled(Tooltip)`
  display: flex;
`

export const DownloadAsset = styled(ButtonUnstyled)(
  ({ theme }) => css`
    ${linkStyles};
    text-decoration: underline;
    font-weight: ${theme.fontWeights.semibold};
  `
)

export const Markdown = styled(BaseMarkdown)`
  overflow: hidden;
`

export const Skeleton = styled.div<{
  $isRefreshedUi: boolean
}>(
  ({ theme, $isRefreshedUi }) => css`
    width: 100%;
    height: 72px;
    border-radius: ${theme.radii.xlarge};

    &:nth-child(even) {
      background-color: ${$isRefreshedUi
        ? theme.colors.background.tertiary.D
        : theme.colors.background.secondary.B};
    }
  `
)
