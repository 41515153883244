import styled, { css } from 'styled-components'

import { ReactComponent as PencilIcon } from '@sketch/icons/pencil-16'
// Shared Styles
import {
  SubtitleTextWrapper,
  TableWrapper,
  Image,
  ImageWrapper,
  ImageElement,
  GridWrapper as GridWrapperBase,
  Name,
  DropdownButtonWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'
import { DropdownButton, truncateLines } from '@sketch/components'
import {
  DroppableArea,
  OnDraggedOverTooltip,
} from '../CollectionShareDrop/CollectionShareDrop.styles'

export const Container = styled.div(
  ({ theme }) => css`
    ${DroppableArea} {
      box-shadow: ${theme.colors.thumbnails.shadow};
      transition: box-shadow ${theme.transitions.duration[2]}
        ${theme.transitions.timing.easeInOut};
      &:hover {
        box-shadow: ${theme.colors.thumbnails.shadowHover};
      }
    }

    ${ImageWrapper} {
      width: 100%;
      margin: 0;
      border-radius: 10px; /* stylelint-disable-line scales/radii */
      background: inherit;
      padding: 0;

      &::before {
        content: none;
      }

      &::after {
        box-shadow: none;
        pointer-events: none;
      }
    }

    ${Image} {
      border-radius: 0;

      &:first-child {
        &,
        & ${ImageElement} {
          border-radius: 6px 0 0 0;
        }
      }

      &:nth-child(3) {
        &,
        & ${ImageElement} {
          border-radius: 0 0 0 6px;
        }
      }

      &::after {
        content: none;
      }
    }

    ${Name} {
      line-height: 1;
      ${truncateLines(2)}
    }
  `
)

export const GridWrapper = styled(GridWrapperBase)(
  ({ theme }) => css`
    height: 72px;
    flex-direction: row;
    align-items: center;
    background: ${theme.colors.background.secondary.A};
    border-radius: 10px; /* stylelint-disable-line scales/radii */
    border: 4px solid ${theme.colors.background.secondary.A};
  `
)

export const GridWrapperLeft = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-shrink: 0;

    width: 64px;
    height: 64px;

    margin-right: 16px;
    background-color: ${theme.colors.background.tertiary.C};
    border-radius: 6px 0 0 6px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background: ${theme.colors.background.secondary.A};
    }

    &::before {
      width: 2px;
      height: 64px;
      top: 0;
      left: 31px;
    }

    &::after {
      width: 64px;
      height: 2px;
      top: 31px;
      left: 0;
    }
  `
)

export const GridWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledName = styled(Name)`
  margin-bottom: 4px;
`

export const StyledSubtitleTextWrapper = styled(SubtitleTextWrapper)`
  display: block;
  line-height: 1;

  b {
    display: block;
  }
`

export const Thumbnail = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  /* Create the border inside */
  :after {
    border-radius: 4px;

    content: '';
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const ThumbnailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2px;
  row-gap: 2px;

  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;
`

export const NoThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
`

export const StyledStatusIcon = styled.div`
  height: 64px;
  width: 64px;
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const InputWrapper = styled.div`
  position: relative;
`

export const StyledInput = styled.input`
  min-width: 100px;
  height: 100%;

  margin: -6px -6px -6px -2px; /* stylelint-disable-line scales/space */
  padding: 2px; /* stylelint-disable-line scales/space */
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.E};

  background: none;
  outline: none;
  overflow: hidden;

  border: none;
  box-shadow: none;
  border-radius: ${({ theme }) => theme.radii.large};

  :focus {
    margin-left: -6px; /* stylelint-disable-line scales/space */
    padding-left: 4px;
    border: 2px solid ${({ theme }) => theme.colors.sketch.E};
  }

  ::selection {
    background: ${({ theme }) => theme.colors.sketch.E};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.state.disabled};
  }
`

export const Pencil = styled(PencilIcon)`
  margin-left: 8px;
  width: 16px;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const DroppableTableWrapper = styled(TableWrapper)<{
  $isDraggedOver: boolean
}>`
  position: relative;
  /*
   * We can't add an inset box-shadow to TableWrapper since it is a <tr> and so
   * is not "display: block".
   * Instead we use a bit of a hack and add a border to the <td>s within to
   * simulate a border around the entire <tr>
   * We add a border to the left, top and bottom of the first <td>, then to the
   * top and bottom of the middle <td>s and finally to the top, right and
   * bottom of the last <td>:
   *
   *    -----------------------------------
   *   | first-child | middle | last-child |
   *    -----------------------------------
   */
  td:first-child {
    ${({ $isDraggedOver }) =>
      $isDraggedOver &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          border: 4px solid ${({ theme }) => theme.colors.sketch.D};
          border-radius: ${({ theme }) => theme.radii.large};
        }
      `}
  }
`

export const DroppableTableWrapperDragTooltip = styled(OnDraggedOverTooltip)`
  top: auto;
  margin-top: -52px;
`

export const GridCollectionItemOption = styled(DropdownButtonWrapper)`
  margin-left: auto;
  margin-right: 8px;

  position: relative;
  inset: 0;

  opacity: 0;
  transform: translate3d(0, -5px, 0);
  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut},
    transform ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut};
  `};

  /* Prevent the button from hiding when the dropdown is open */
  [aria-expanded='true'] ${DropdownButton} {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &:has([aria-expanded='true']),
  :focus-within {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @media (hover: none) {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
