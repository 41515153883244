import styled, { css } from 'styled-components'

import { breakpoint, lightTheme as theme } from '@sketch/global-styles'

import {
  ButtonUnstyled,
  Avatar as BaseAvatar,
  Dropdown,
  Pill as BasePill,
} from '@sketch/components'

import { ReactComponent as ArrowRight } from '@sketch/icons/chevron-right-16'

export const Icon = styled.svg<{ $isActive?: boolean }>`
  flex-shrink: 0;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.sketch.A : theme.colors.foreground.secondary.D};
`

export const Pill = styled(BasePill)<{ withNumberDisplay: boolean }>`
  ${({ withNumberDisplay, theme }) => css`
    aside & {
      position: absolute;
      top: ${withNumberDisplay ? -6 : 4}px;
      right: ${withNumberDisplay ? -6 : 11}px;
    }

    ${withNumberDisplay
      ? css`
          margin-left: auto;
          flex-shrink: 0;

          .active & {
            color: ${theme.colors.sketch.A};
            background: ${theme.colors.sketch.F};
          }
        `
      : css`
          width: 6px;
          height: 6px;
          padding: 0;
          background: ${theme.colors.sketch.A};
        `}
  `}
`

export const KeyWrapper = styled(BasePill)`
  margin-left: auto;
  border: 2px solid ${({ theme }) => theme.colors.foreground.primary.A};

  ${Pill} + & {
    margin-left: 4px;
  }
`

export const Button = styled(ButtonUnstyled)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  border-radius: 6px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.state.hover};

    ${Icon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }

  &:active {
    background: ${({ theme }) => theme.colors.background.secondary.C};

    ${Icon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.sketch.F};
    pointer-events: none;

    ${Icon} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }
`

export const SidebarHeaderRight = styled.div`
  display: flex;
  align-items: center;

  margin-right: -8px;

  /* stylelint-disable-next-line no-descending-specificity */
  ${KeyWrapper} {
    position: absolute;
    top: -6px;
    right: -6px;
  }
`

export const UserName = styled.p`
  margin-right: 12px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Avatar = styled(BaseAvatar)<{ $isRefreshedUi: boolean }>`
  flex-shrink: 0;

  /* When when remove the FF we can remove the box-shadow from the "BaseAvatar" components */
  ${({ $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      box-shadow: none;
    `}
`

/**
 * Dropdown Styles
 */

const commonDropdownItemStyles = css`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 8px 12px;
  line-height: 1;

  ${({ theme }) => css`
    transition: opacity ${theme.transitions.duration[2]}
        ${theme.transitions.timing.easeInOut},
      background-color ${theme.transitions.duration[2]}
        ${theme.transitions.timing.easeInOut};

    color: ${theme.colors.foreground.secondary.A};
  `};

  :hover {
    opacity: 1;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  ${Icon} {
    margin-right: 12px;
  }

  ${breakpoint('base', 'xs')`
  opacity: 1;
`}
`

export const ArrowIcon = styled(Icon).attrs({
  as: ArrowRight,
})`
  &&& {
    margin-right: 0;
  }
`

export const StyledDropdownItem = styled(Dropdown.Item)<{
  $isActive?: boolean
  disabled?: boolean
}>(
  ({ $isActive, disabled }) => css`
    ${commonDropdownItemStyles}

    background-color: ${$isActive ? theme.colors.sketch.F : undefined};
    color: ${$isActive ? theme.colors.sketch.A : undefined};
    opacity: ${disabled ? 0.5 : 1};
    cursor: pointer;
  `
)

export const StyledDropdownItemNavLink = styled(Dropdown.Item.NavLink)`
  ${commonDropdownItemStyles}

  &.active {
    ${Icon} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }
`

export const DropdownHeader = styled.div`
  padding: 8px 16px 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const DropdownUserName = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  line-height: 1.462; /* stylelint-disable-line scales/line-height */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DropdownUserEmail = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  line-height: 1.333; /* stylelint-disable-line scales/line-height */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NavLinkWrapper = styled.div`
  display: flex;
  gap: 4px;
`
