import React from 'react'
import { useFlag } from '@sketch/modules-common'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { SidebarWorkspaceNoticeTrial } from './WorkspaceSidebarNoticeTrial'
import {
  useCurrentWorkspaceSidebarNoticeId,
  WorkspaceNoticeId,
} from './workspaceNotices'
import { WorkspaceNoticeComponentProps } from './types'
import { WorkspaceSidebarNoticePersonalWorkspace } from './WorkspaceSidebarNoticePersonalWorkspace'
import { WorkspaceSidebarNoticeCanceledSubscription } from './WorkspaceSidebarNoticeCanceledSubscription'
import { WorkspaceSidebarNoticeEducationCancel } from './WorkspaceSidebarNoticeEducationCancel'

import { workspaceNoticeIdToReactElementRecord as workspaceNoticeIdToReactElementRecordOld } from '../WorkspaceSidebarNoticeOld'

type WorkspaceSidebarNoticeProps = {
  workspace: WorkspaceMinimalFragment
}

/**
 * Component shown in the sidebar displaying a notice about the workspace status
 *  (trial, trial expired, subscription cancel, etc...) with potential call to action.
 */
export function WorkspaceSidebarNotice({
  workspace,
}: WorkspaceSidebarNoticeProps) {
  const isRefreshedUi = useFlag('ui-refresh-24')

  const mainWorkspaceNoticeId = useCurrentWorkspaceSidebarNoticeId(workspace)

  const WorkspaceNoticeComponent =
    workspaceNoticeIdToReactElementRecord[mainWorkspaceNoticeId]

  const WorkspaceNoticeComponentOld =
    workspaceNoticeIdToReactElementRecordOld[mainWorkspaceNoticeId]

  if (mainWorkspaceNoticeId && isRefreshedUi && WorkspaceNoticeComponent) {
    return <WorkspaceNoticeComponent workspace={workspace} />
  }

  if (mainWorkspaceNoticeId && !isRefreshedUi && WorkspaceNoticeComponentOld) {
    return <WorkspaceNoticeComponentOld workspace={workspace} />
  }

  // No notice to show
  return null
}

/** Associate a react component to render with each notice id */
const workspaceNoticeIdToReactElementRecord: Record<
  WorkspaceNoticeId,
  React.ComponentType<WorkspaceNoticeComponentProps> | null
> = {
  TRIAL: SidebarWorkspaceNoticeTrial,
  PERSONAL_WORKSPACE: WorkspaceSidebarNoticePersonalWorkspace,
  SUBSCRIPTION_CANCELED: WorkspaceSidebarNoticeCanceledSubscription,
  EDUCATION_CANCEL: WorkspaceSidebarNoticeEducationCancel,
}
