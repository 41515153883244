import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

const ignoredProps = ['active', 'loading', 'fill', 'size', 'variant', 'Icon'];
// Generic button styles
///////////////////////////////////////////////////////////////////////////////
const BaseButtonStyle = css(({ theme: { colors, fontSizes, fontWeights, radii } }) => css `
    /* Font */
    font-size: ${fontSizes.E};
    font-weight: ${fontWeights.semibold};

    /* Text */
    text-align: left;
    white-space: nowrap;

    /* Layout */
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* Default dimensions to the 40 size */
    padding: 10px 12px;
    height: 40px;

    /* Border */
    border-radius: ${radii.large};
    border: 1px solid transparent;

    /* Color */
    color: ${colors.foreground.primary.A};

    /* Prevents Chrome flicker on modal - https://github.com/sketch-hq/Cloud/issues/3723 */
    backface-visibility: hidden;

    /* Other */
    user-select: none;
    box-shadow: 0 1px 2px 0 ${colors.shadow.outer};
    cursor: pointer;
    appearance: none;

    /* Special cases */
    & + button,
    & + a {
      margin-left: 16px;
    }

    &:hover {
      color: ${colors.foreground.primary.A};
    }

    &:active {
      color: ${colors.foreground.primary.B};
    }
  `);
const BaseButton = styled.button.withConfig({
    shouldForwardProp: prop => !ignoredProps.includes(prop),
})(({ theme: { fontSizes }, fill, loading }) => css `
    ${BaseButtonStyle}

    ${() => variant({
    prop: 'size',
    variants: {
        '48': {
            fontSize: fontSizes.F,
            padding: '12px 14px',
            height: 48,
        },
        '40': {
            fontSize: fontSizes.E,
            padding: '10px 12px',
            height: 40,
        },
        '32': {
            fontSize: fontSizes.D,
            padding: '10px 12px',
            height: 32,
        },
        '24': {
            fontSize: fontSizes.C,
            padding: '4px 8px',
            height: 24,
            '& + button, & + a': {
                marginLeft: '12px',
            },
        },
    },
})}

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      /** For fading out the shadow when disabled */
      box-shadow: none;
    }

    ${fill &&
    css `
      display: flex;
      width: 100%;
    `};

    ${loading &&
    css `
      &&& {
        color: transparent;
        overflow: hidden;

        &[disabled] {
          opacity: 1;
        }
      }
    `};
  `);
const StyledButton = styled(BaseButton)(({ theme: { colors }, disabled }) => css `
    ${() => variant({
    variants: {
        primary: {
            color: disabled
                ? colors.ui.buttons.foregroundDisabled
                : colors.ui.buttons.foreground,
            backgroundColor: disabled ? colors.sketch.E : colors.sketch.B,
            '&:hover, &:active': {
                backgroundColor: colors.sketch.A,
            },
            '&:hover': {
                color: colors.ui.buttons.foregroundHover,
            },
            '&:active': {
                color: colors.ui.buttons.foregroundActive,
            },
        },
        'primary-untinted': {
            color: disabled
                ? colors.ui.buttons.foregroundDisabled
                : colors.ui.buttons.foreground,
            backgroundColor: disabled
                ? colors.state.disabled
                : colors.buttons.primaryUntinted.backgroundColor,
            '&:hover': {
                color: colors.ui.buttons.foregroundHover,
                backgroundColor: colors.buttons.primaryUntinted.hover.backgroundColor,
            },
            '&:active': {
                color: colors.ui.buttons.foregroundActive,
                backgroundColor: colors.buttons.primaryUntinted.active.backgroundColor,
            },
        },
        secondary: {
            borderColor: colors.buttons.secondary.border,
            backgroundClip: 'padding-box' /* simulates outline border */,
            color: disabled ? colors.sketch.E : colors.buttons.secondary.color,
            backgroundColor: colors.buttons.secondary.backgroundColor,
            /* This can't be inside @media (hover: hover) to avoid a specificity problem */
            '&:hover': {
                color: colors.buttons.secondary.hover.color,
            },
            /* Avoids sticky hover background colors when touching buttons on mobile */
            '@media (hover: hover)': {
                '&:hover': {
                    backgroundClip: 'border-box',
                    backgroundColor: colors.buttons.secondary.hover.backgroundColor,
                },
            },
            '&:active': {
                color: colors.buttons.secondary.active.color,
                backgroundColor: colors.buttons.secondary.active.backgroundColor,
                backgroundClip: 'border-box',
            },
        },
        'secondary-untinted': {
            boxShadow: 'none',
            borderColor: colors.buttons.secondaryUntinted.border,
            backgroundClip: 'padding-box' /* simulates outline border */,
            color: disabled
                ? colors.state.disabled
                : colors.buttons.secondaryUntinted.color,
            backgroundColor: colors.buttons.secondaryUntinted.hover.backgroundColor,
            /* This can't be inside @media (hover: hover) to avoid a specificity problem */
            '&:hover, &:active': {
                color: colors.buttons.secondaryUntinted.hover.color,
                backgroundColor: colors.buttons.secondaryUntinted.hover.backgroundColor,
            },
            /* Avoids sticky hover background colors when touching buttons on mobile */
            '@media (hover: hover)': {
                '&:hover': {
                    backgroundClip: 'border-box',
                    backgroundColor: colors.buttons.secondaryUntinted.hover.backgroundColor,
                },
            },
            '&:active': {
                color: colors.buttons.secondaryUntinted.active.color,
                backgroundColor: colors.buttons.secondaryUntinted.active.backgroundColor,
                backgroundClip: 'border-box',
            },
        },
        tertiary: {
            boxShadow: 'none',
            color: disabled
                ? colors.foreground.secondary.D
                : colors.foreground.secondary.A,
            backgroundColor: colors.background.tertiary.C,
            '&:hover, &:active': {
                color: colors.foreground.secondary.A,
                backgroundColor: colors.background.tertiary.A,
            },
        },
        negative: {
            color: colors.ui.buttons.foreground,
            backgroundColor: disabled
                ? colors.state.negative.D
                : colors.state.negative.B,
            boxShadow: disabled
                ? '0 1px 5px 0 rgba(109, 114, 120, 0.25)'
                : '0 1px 5px 0 rgba(191, 28, 28, 0.25)',
            '&:hover': {
                backgroundColor: colors.state.negative.D,
            },
            ' &:active': {
                color: colors.ui.buttons.foregroundActive,
                backgroundColor: colors.state.negative.A,
            },
        },
        'negative-secondary': {
            color: disabled
                ? colors.state.negative.D
                : colors.buttons.secondaryNegative.color,
            backgroundColor: disabled
                ? colors.background.secondary.A
                : colors.background.secondary.A,
            borderColor: colors.buttons.secondary.border,
            backgroundClip: 'padding-box' /* simulates outline border */,
            '&:hover, &:active': {
                color: colors.state.negative.A,
                backgroundColor: colors.background.secondary.C,
            },
        },
        ghost: {
            color: colors.foreground.secondary.D,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            '&:hover': {
                color: colors.foreground.secondary.A,
                backgroundColor: colors.state.hover,
            },
            '&:active': {
                color: colors.foreground.secondary.A,
                backgroundColor: colors.border.A,
            },
            '&[disabled]': {
                color: colors.state.disabled,
            },
            '&[aria-expanded="true"]': {
                backgroundColor: colors.sketch.F,
                color: colors.sketch.A,
            },
        },
    },
})}
  `);
// Primary button
///////////////////////////////////////////////////////////////////////////////
const PrimaryButtonStyle = css(({ theme: { colors } }) => css `
    color: ${colors.ui.buttons.foreground};
    background-color: ${colors.sketch.B};

    &:hover,
    &:active {
      background-color: ${colors.sketch.A};
    }

    &:active {
      color: ${colors.ui.buttons.foregroundActive};
    }

    &:hover {
      color: ${colors.ui.buttons.foregroundHover};
    }

    ${isDisabled(css `
        color: ${colors.foreground.primary.A};
        background-color: ${colors.sketch.E};
      `)};
  `);
///////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line prettier/prettier
const isDisabled = (style) => ({ disabled, success, error, loading, }) => disabled && !success && !error && !loading && style;
// Wrappers
///////////////////////////////////////////////////////////////////////////////
const IconWrapper = styled.span `
  display: flex;
  margin-right: 8px;

  /* Force SVG icons to not have line-height to the IconWrapper */
  [role='img'] {
    display: block;
  }
`;
const inlineFlexCenter = css `
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
const ButtonWrapper = styled.div `
  ${inlineFlexCenter}
  /**
   * Here we will tweak the line height of the button text
   * so it will be centered correctly on the button. Using any of the values
   * from the DS (0, 0.75 or 1) won't work for this somehow
   */
  line-height: 100%; /* stylelint-disable-line scales/line-height */
`;
const ButtonIcon = styled.div `
  ${inlineFlexCenter}

  margin-right: 4px;
`;
const LoadingWrapper = styled.div `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export { BaseButton, BaseButtonStyle as ButtonBaseStyle, ButtonIcon, IconWrapper as ButtonIconWrapper, PrimaryButtonStyle as ButtonPrimaryStyle, ButtonWrapper, LoadingWrapper, StyledButton, ignoredProps };
