import styled, { css } from 'styled-components';
import { ReactComponent } from '@sketch/icons/logo-black';
import '../Button/index.js';
import '../Box/index.js';
import { NavbarLinkWrapper } from './NavbarLinkWrapper.js';
import '../Truncate/index.js';
import { breakpoint } from '@sketch/global-styles';
import { truncateStyles } from '../Truncate/Truncate.js';
import { Flex, Text } from '../Box/BoxSystem.js';
import { IconButton } from '../Button/IconButton.js';

const navbarTitleCss = css `
  ${truncateStyles};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  min-width: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const GradientSeparator = styled.span `
  display: flex;
  align-self: center;
  flex: 0 0 auto;
  height: 4rem;
  width: 1px;
  margin: 0 4px;
  opacity: 0.08;
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.65) 32%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.65) 68%,
    rgba(0, 0, 0, 0) 90%
  );
`;
const NavbarSection = styled(Flex) `
  display: flex;
  height: 100%;
  min-height: 100%;
  align-items: center;
  flex: 1 0px;
  overflow: hidden;
  justify-content: ${({ align = 'start' }) => align === 'center' ? 'center' : `flex-${align}`};
  gap: 4px;
`;
const FixedWidthNavbarSection = styled(NavbarSection) `
  flex: none;
`;
const NavbarLogoWrapper = styled(NavbarLinkWrapper) `
  display: flex;
  height: 3rem;
  width: 3rem;
  align-items: center;
  justify-content: center;
  padding: 0;
`;
const LogoBlack = styled(ReactComponent) `
  width: 20px;
  height: 18.75px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`;
const NavbarTitle = styled(Text).attrs({
    'data-testid': 'navbar-title',
}) `
  ${navbarTitleCss};
`;
const NavbarBase = styled.div(({ theme, isSidebarLeftOpen, fixed, singleContent, $isRefreshedUi, isDocumentView, }) => css `
    position: relative;
    display: flex;
    /* Adding 1px to make room for border-bottom */
    height: ${theme.header.height + 1}px;
    border-bottom: 1px solid ${theme.colors.border.B};
    width: 100%;
    align-items: center;
    justify-content: ${singleContent ? 'flex-end' : 'space-between'};
    background: ${theme.colors.background.secondary.A};

    ${!$isRefreshedUi &&
    css `
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: transform, opacity;

      transform: ${isSidebarLeftOpen
        ? `translateX(${theme.sidebar.leftWidth}px)`
        : undefined};

      /* For mobile, when the sidebar is opened we also need to move the banner (if there is any) */
      & + aside[role='alert'] {
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
        transition-property: transform, opacity;

        transform: ${isSidebarLeftOpen
        ? `translateX(${theme.sidebar.leftWidth}px)`
        : undefined};
      }

      ${breakpoint('sm') `
      transform: none;
    `};
    `}

    ${fixed &&
    css `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    `};

    ${$isRefreshedUi &&
    css `
      border: none;
      height: ${theme.header.height}px;
      background: ${isDocumentView
        ? theme.colors.background.secondary.A
        : theme.colors.background.secondary.B};
    `}
  `);
const NavbarDivider = styled(GradientSeparator) `
  display: none;
`;
const NavbarButton = styled(IconButton).attrs(({ size }) => ({
    size: size || 18,
})) `
  position: relative;
  height: 3rem;
  width: 3rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  /* Show the button as hovered when it's inside of a expanded dropdown */
  [aria-expanded='true'] & {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  ${props => props.disabled &&
    css `
      opacity: 0.4;
    `};

  ${props => {
    if (props.active) {
        return css `
        color: ${({ theme }) => theme.colors.sketch.A};

        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.sketch.A};
        }

        &:active {
          color: ${({ theme }) => theme.colors.sketch.A};
        }
      `;
    }
}}
`;

export { FixedWidthNavbarSection, GradientSeparator, LogoBlack, NavbarBase, NavbarButton, NavbarDivider, NavbarLogoWrapper, NavbarSection, NavbarTitle, navbarTitleCss };
