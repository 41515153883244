import React, { FC, CSSProperties } from 'react'

import { Tooltip, TimeAgo, Box, Flex, useForDesktop } from '@sketch/components'
import { routes, versionedRoutes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'

import {
  AddShareMemberNotificationListItem,
  CommentNotificationListItem,
  DownloadableAssetNotificationListItem,
  MentionNotificationListItem,
  StarNotificationListItem,
} from './NotificationListItem/index'

import {
  NotificationContainer,
  NotificationContainerMobile,
  NotificationFlex,
  NotificationFlexMobile,
  NotificationItemContainer,
  NotificationItemContainerMobile,
  NotificationsDate,
  NotificationsDateMobile,
  NotificationsType,
  UnreadIndicator,
  MobileHeader,
  MarkAsReadButtonContainer,
  MarkAsReadIcon,
  SubscribeIcon,
  MarkNotificationAsReadButton,
  SubscriptionButton,
  UnsubscribeIcon,
} from './NotificationListItem.styles'

import {
  NotificationInfoFragment,
  CommentNotificationFragment,
  MentionNotificationFragment,
  DownloadableAssetRenderedNotificationFragment,
  AddShareMemberNotificationFragment,
  StarNotificationFragment,
  useUpdateNotificationSubscriptionMutation,
} from '@sketch/gql-types'
// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import {
  NotificationSubscriptionStatus,
  NotificationSubscriptionState,
} from '@sketch/gql-types/expansive'

/**
 * TYPES
 */
interface NotificationListItemProps {
  notification: NotificationInfoFragment
  markNotificationAsRead: () => void
  isVisuallyRead?: boolean
  isRead?: boolean
}

interface MarkAsReadButtonProps {
  onClick: (event: React.MouseEvent) => void
  text?: string
}

interface SubscribeButtonProps {
  subscriptionStatus: NotificationSubscriptionStatus
  onClick: (status: NotificationSubscriptionState) => void
  text?: string
}

/**
 * HELPERS
 */
const pointerEventsNoneStyles: CSSProperties = { pointerEvents: 'none' }

// User defined type guards to easily narrow down NotificationInfoFragment
const isCommentNotification = (
  notification?: NotificationInfoFragment
): notification is CommentNotificationFragment =>
  notification?.__typename === 'CommentNotification'

const isMentionNotification = (
  notification?: NotificationInfoFragment
): notification is MentionNotificationFragment =>
  notification?.__typename === 'MentionNotification'

const isStarNotification = (
  notification?: NotificationInfoFragment
): notification is StarNotificationFragment =>
  notification?.__typename === 'StarNotification'

const isAddShareMemberNotification = (
  notification?: NotificationInfoFragment
): notification is AddShareMemberNotificationFragment =>
  notification?.__typename === 'AddShareMemberNotification'

const isDownloadableAssetNotification = (
  notification?: NotificationInfoFragment
): notification is DownloadableAssetRenderedNotificationFragment =>
  notification?.__typename === 'DownloadableAssetRenderedNotification'

/**
 * HELPER COMPONENTS
 */
const MarkAsReadButton: FC<MarkAsReadButtonProps> = ({
  onClick,
  text,
  ...props
}) => {
  return (
    <Tooltip
      spacing="8px"
      placement="top"
      content="Mark as Read"
      // HACK: set pointer events to none to prevent hover over the tooltip
      // itself which would make the tooltip dissapear for a short while and
      // then reappear again resulting in flickering
      contentStyle={pointerEventsNoneStyles}
      {...props}
    >
      <MarkNotificationAsReadButton
        aria-label="Mark notification as read"
        onClick={onClick}
      >
        <MarkAsReadIcon />
        {text && <Box ml={3}>{text}</Box>}
      </MarkNotificationAsReadButton>
    </Tooltip>
  )
}

const SubscribeButton: FC<SubscribeButtonProps> = ({
  onClick,
  text,
  subscriptionStatus,
  ...props
}) => {
  const isOn = subscriptionStatus === 'ON'
  const helpText = isOn ? 'Unfollow' : 'Follow'

  return (
    <Tooltip
      spacing="8px"
      placement="top"
      content={helpText}
      // HACK: set pointer events to none to prevent hover over the tooltip
      // itself which would make the tooltip dissapear for a short while and
      // then reappear again resulting in flickering
      contentStyle={pointerEventsNoneStyles}
      {...props}
    >
      <SubscriptionButton
        aria-label={`${helpText} to notification`}
        onClick={(event: React.MouseEvent) => {
          // Prevent click from closing the popover
          event.preventDefault()
          event.stopPropagation()
          setTimeout(() => {
            onClick(isOn ? 'OFF' : 'ON')
          })
        }}
      >
        {isOn ? <UnsubscribeIcon /> : <SubscribeIcon />}
        {text && <Box ml={3}>{text}</Box>}
      </SubscriptionButton>
    </Tooltip>
  )
}

const getNotificationType = (
  notification: NotificationInfoFragment
): string => {
  if (isCommentNotification(notification)) {
    return 'Comment'
  }
  if (isMentionNotification(notification)) {
    return 'Mention'
  }
  if (isAddShareMemberNotification(notification)) {
    return 'Membership'
  }
  if (isDownloadableAssetNotification(notification)) {
    return 'Asset'
  }
  if (isStarNotification(notification)) {
    return 'Star'
  }
  return '-'
}

const notificationPath = (notification: NotificationInfoFragment): string => {
  if (isDownloadableAssetNotification(notification)) {
    if (!notification?.share || !notification?.notifiedVersion) {
      ErrorHandler.shouldNeverHappen('Invalid share or version Id')
      // At this point, return an emtpy string or router link will fail
      // the validation
      return ''
    }

    return versionedRoutes.SHARE_VIEW.VERSION.create({
      shareID: notification?.share?.identifier,
      versionShortId: notification?.notifiedVersion?.shortId,
    })
  }

  if (
    (isCommentNotification(notification) ||
      isMentionNotification(notification)) &&
    notification.comment &&
    (notification.comment.annotation.currentSubject.__typename === 'Artboard' ||
      notification.comment.annotation.currentSubject.__typename === 'Page')
  ) {
    const shareId = notification?.share?.identifier || ''
    const { annotation } = notification.comment
    const subjectType = annotation.currentSubject.__typename
    let subjectId = ''
    if (subjectType === 'Artboard') {
      subjectId = annotation.currentSubject.permanentArtboardShortId
    } else if (subjectType === 'Page') {
      subjectId = annotation.currentSubject.uuid!
    } else {
      // We have already restricted the annotation subject to either `Artboard` or `Page`
      // above so this branch should never be reached. However to satisfy the
      // typescript compiler it is included
      ErrorHandler.shouldNeverHappen('Unexpected annotation subject')
    }

    return routes.ANNOTATION_REDIRECT.create({
      shareId,
      annotationId: annotation.identifier,
      query: { subjectType, subjectId },
    })
  }

  if (!notification.path) {
    if (
      isCommentNotification(notification) &&
      notification.share &&
      notification.artboard?.permanentArtboardShortId
    ) {
      return routes.ARTBOARD_DETAIL.create({
        shareID: notification.share.identifier,
        permanentArtboardShortId:
          notification.artboard.permanentArtboardShortId,
      })
    }

    ErrorHandler.shouldNeverHappen('Invalid path')
    // At this point, return an emtpy string or router link will fail
    // the validation
    return ''
  }

  return notification.path
}

/**
 * MAIN COMPONENT
 */

const NotificationListItem: FC<NotificationListItemProps> = ({
  notification,
  markNotificationAsRead,
  isVisuallyRead,
  isRead,
  ...props
}) => {
  const { showToast } = useToast()
  const isDesktop = useForDesktop()

  const [
    subscriptionStatusMutation,
  ] = useUpdateNotificationSubscriptionMutation({
    onError: 'show-toast',
    onCompleted: data =>
      data.setNotificationSubscription.share?.subscriptionStatus === 'ON'
        ? showToast('Following')
        : showToast('Unfollowed'),
  })

  // Renders different type of notifications
  const renderNotification = () => {
    // COMMENT NOTIFICATION
    if (isCommentNotification(notification)) {
      return <CommentNotificationListItem notification={notification} />
    }

    // MENTION NOTIFICATION
    if (isMentionNotification(notification)) {
      return <MentionNotificationListItem notification={notification} />
    }

    // STAR NOTIFICATION
    if (isStarNotification(notification)) {
      return <StarNotificationListItem notification={notification} />
    }

    // MEMBERSHIP NOTIFICATION
    if (isAddShareMemberNotification(notification)) {
      return (
        <AddShareMemberNotificationListItem
          notification={notification}
          markNotificationAsRead={markNotificationAsRead}
        />
      )
    }

    // ASSET READY NOTIFICATION
    if (isDownloadableAssetNotification(notification)) {
      return (
        <DownloadableAssetNotificationListItem
          notification={notification}
          markNotificationAsRead={markNotificationAsRead}
        />
      )
    }

    // ANY OTHER NOTIFICATION
    // We should never reach this point as every type of notification should
    // be handled individually
    ErrorHandler.shouldNeverHappen(
      `This type of notification is not recognized: ${notification.__typename}`
    )
  }

  const onClick = () => {
    if (
      notification.__typename === 'CommentNotification' &&
      !notification.path
    ) {
      showToast('This reply has been deleted', 'negative')
    }

    if (notification.isRead) {
      return
    }

    markNotificationAsRead()
  }

  const handleSubscriptionStatus = (state: NotificationSubscriptionState) => {
    let annotationIdentifier = undefined
    let shareIdentifier = undefined

    // checking if notification is identified by a annotation or share id
    if (
      (isCommentNotification(notification) ||
        isMentionNotification(notification)) &&
      notification.comment
    ) {
      annotationIdentifier = notification.comment.annotation.identifier
    } else if (
      isCommentNotification(notification) &&
      notification.share &&
      notification.artboard?.permanentArtboardShortId
    ) {
      shareIdentifier = notification.share.identifier
    }

    subscriptionStatusMutation({
      variables: {
        annotationIdentifier,
        shareIdentifier,
        state,
      },
    })
  }

  /**
   * Standard notification structure.
   *
   * We have 5 types of notifications with slightly different structure:
   * - Comment/Reply
   * - Mention
   * - Membership
   * - Assets ready
   * - Assets failed
   *
   * UI wise we show the `read` as a dot positioned to the left of the
   * notification, and we split the `read` and `unread` notifications by
   * splitting the list.
   */

  if (isDesktop) {
    return (
      <NotificationContainer
        data-testid="notification-list-item"
        to={notificationPath(notification)}
        onClick={onClick}
        $isRead={isRead}
        {...props}
      >
        {!isVisuallyRead && (
          <UnreadIndicator
            aria-label="Unread notification"
            data-testid="notification-unread-indicator"
          />
        )}

        <NotificationFlex>
          <NotificationItemContainer>
            {renderNotification()}
          </NotificationItemContainer>
          <NotificationsType
            aria-label="Notification type"
            data-testid="notification-type"
          >
            {getNotificationType(notification)}
          </NotificationsType>
          <NotificationsDate>
            <TimeAgo date={notification.date!} />
          </NotificationsDate>
          <MarkAsReadButtonContainer>
            {!isVisuallyRead && (
              <MarkAsReadButton
                onClick={event => {
                  // Prevent click from closing the popover
                  event.preventDefault()
                  event.stopPropagation()
                  setTimeout(() => {
                    markNotificationAsRead()
                  })
                }}
              />
            )}
            {'share' in notification && (
              <SubscribeButton
                subscriptionStatus={
                  notification.share?.subscriptionStatus ?? 'OFF'
                }
                onClick={handleSubscriptionStatus}
              />
            )}
          </MarkAsReadButtonContainer>
        </NotificationFlex>
      </NotificationContainer>
    )
  }

  // mobile layout
  return (
    <NotificationContainerMobile
      data-testid="notification-list-item"
      to={notificationPath(notification)}
      onClick={onClick}
      $isRead={isRead}
      {...props}
    >
      <NotificationFlexMobile>
        <Flex
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          pb={4}
        >
          <MobileHeader $isRead={!!isRead}>
            {!isVisuallyRead && (
              <UnreadIndicator
                aria-label="Unread notification"
                data-testid="notification-unread-indicator"
              />
            )}
            <NotificationsType
              aria-label="Notification type"
              data-testid="notification-type"
            >
              {getNotificationType(notification)}
            </NotificationsType>
          </MobileHeader>
          <NotificationsDateMobile>
            <TimeAgo date={notification.date!} />
          </NotificationsDateMobile>
        </Flex>

        <NotificationItemContainerMobile>
          {renderNotification()}
        </NotificationItemContainerMobile>

        <MarkAsReadButtonContainer>
          {!isVisuallyRead && (
            <MarkAsReadButton
              text="Mark as read"
              onClick={event => {
                // Prevent click from closing the popover
                event.preventDefault()
                event.stopPropagation()
                setTimeout(() => {
                  markNotificationAsRead()
                })
              }}
            />
          )}
        </MarkAsReadButtonContainer>
      </NotificationFlexMobile>
    </NotificationContainerMobile>
  )
}

export default NotificationListItem
